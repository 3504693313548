import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Grid,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { Form, Formik } from "formik";
import Modal from "@mui/material/Modal";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { postRequest } from "backendServices/ApiCalls";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Alert, Stack } from "@mui/material";

const Userpassword = ({ userData }) => {
  const [openUpdate, setOpenUpdate] = useState(false);
  const location = useLocation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const nav = useNavigate();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  // const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    color: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const validationSchema = yup.object().shape({
    otp: yup.string().required("OTP is required"),
    password: yup.string().required("Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  useEffect(() => {
    if (location.pathname === "/profile") {
      setOpenUpdate(false);
    } else if (userData?.profileupdated === "no" && userData?.user_type === 'csc') {
      setOpenUpdate(true);
    }
  }, [userData, openUpdate]);

  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Modal
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style} style={{ minWidth: "600px" }}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Update Profile
          </Typography>
          <Alert severity="warning">Please complete your profile</Alert>
          <List disablePadding sx={{ mb: 2, mt: 5, textAlign: "center" }}>
            <Link to="/profile">
              <Button variant="contained">Go to Profile Page</Button>
            </Link>
          </List>
        </Div>
      </Modal>
    </>
  );
};

export default Userpassword;
