import React, { useContext } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Alert,
  TextField,
  IconButton,
} from "@mui/material";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ContentCopy } from "@mui/icons-material";

const validationSchema = yup.object({
  fname: yup
    .string("Enter recipient first name")
    .required("first name is required"),
  lname: yup
    .string("Enter recipient last name")
    .required("last name is required"),
  email: yup
    .string("Enter recipient email address")
    .required("email is required"),
  phone: yup
    .string("Enter recipient phone number")
    .required("phone number is required"),
  message: yup.string("Enter message").required("message is required"),
});

function CustomerDashbaord() {
  const navigate = useNavigate();
  const {
    loginUserData,
    loading,
    handleOpen,
    handleClose,
    open,
    alertData,
    setalertData,
  } = useContext(CustomProvider);
  let userData = loginUserData;

  if (userData.user_type === 'csc') {
    navigate('/dashboard')
  }

  const referrallink = loginUserData?.referrallink;

  const handleCopy = () => {
    navigator.clipboard.writeText(referrallink);
    setalertData({
      show: true,
      message: "Referral link copied to clipboard",
      variant: "success",
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          minHeight: "100px",
          backgroundColor: "#172C60",
          borderRadius: "25px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            color="white"
            textAlign={"center"}
            sx={{ mt: 5, fontSize: 50 }}
          >
            Weclome to <samp style={{ color: "#C7A75A" }}>ROI</samp>
          </Typography>
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant="h4" color="white" sx={{ mt: 5 }}>
              We will be your partner in unseen wellness.
            </Typography>
            <Typography variant="h4" color="white" sx={{ mt: 1 }}>
              Let's start nurturing your inner health.
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} sx={{ mt: 5, mb: 5 }}>
          <Grid container>
            <Grid item xs={12} md={4} sx={{ mt: 3 }}>
              <Link to={"/roishop"} style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    minHeight: "100px",
                    backgroundColor: "white",
                    marginLeft: 5,
                    marginRight: 5,
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Grid container>
                    <Grid xs={3} textAlign={"right"}>
                      <LocalGroceryStoreIcon
                        sx={{
                          color: "#172C60",
                          fontSize: "35px",
                          mr: 1,
                          mt: 4,
                        }}
                      />
                    </Grid>
                    <Grid xs={9}>
                      <Typography
                        variant="h4"
                        sx={{ color: "#172C60", fontSize: "35px", mt: 3.5 }}
                      >
                        <b>ROI Shop</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} md={4} sx={{ mt: 3 }}>
              <Box
                sx={{
                  minHeight: "100px",
                  backgroundColor: "white",
                  marginLeft: 5,
                  marginRight: 5,
                  borderRadius: "50px",
                }}
              >
                <Grid container>
                  <Grid xs={3} textAlign={"right"}>
                    <CurrencyRupeeIcon
                      sx={{ color: "#172C60", fontSize: "40px", mr: 1, mt: 4 }}
                    />
                  </Grid>
                  <Grid xs={9}>
                    <Typography
                      variant="h4"
                      sx={{ color: "#172C60", fontSize: "30px", mt: 2 }}
                    >
                      <b>ROI Money</b>
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: "#172C60", fontSize: "20px", mt: -1 }}
                    >
                      {userData.roibalance}AED
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ mt: 3 }}>
              <Box
                sx={{
                  minHeight: "100px",
                  backgroundColor: "white",
                  marginLeft: 5,
                  marginRight: 5,
                  borderRadius: "50px",
                }}
              >
                <Grid container>
                  <Grid xs={3} textAlign={"right"}>
                    <GroupAddIcon
                      sx={{ color: "#172C60", fontSize: "40px", mr: 1, mt: 4 }}
                    />
                  </Grid>
                  <Grid xs={9}>
                    <Typography
                      variant="h4"
                      sx={{ color: "#172C60", fontSize: "30px", mt: 2 }}
                    >
                      <b>Referral</b>
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#172C60" }}
                        onClick={handleOpen}
                      >
                        Invite
                      </Button>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#172C60" }}
                        component={Link}
                        to={"/referrals"}
                      >
                        Summary
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={open}>
          <Div sx={style} alignItems="center">
            <Typography id="transition-modal-title" variant="h4" component="h2">
              Send Invitation Link
              <CloseIcon
                onClick={handleClose}
                sx={{
                  float: "right",
                  color: "white",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Typography>
            {alertData.show && (
              <Alert severity="success">{alertData.message}</Alert>
            )}
            <center>
              <Formik
                validateOnChange={true}
                initialValues={{
                  message: "",
                  phone: "",
                  email: "",
                  lname: "",
                  fname: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  // onSubmitForm(data, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 2 }}>
                      <TextField
                        sx={{ width: "100%" }}
                        label={"Invite users with this link"}
                        type="text"
                        disabled
                        value={referrallink}
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <IconButton sx={{ color: "white" }}>
                              <ContentCopy onClick={handleCopy} />
                            </IconButton>
                          ),
                        }}
                      />
                    </Div>
                  </Form>
                )}
              </Formik>
            </center>
          </Div>
        </Fade>
      </Modal>
    </>
  );
}

export default CustomerDashbaord;
