import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { getdepositlist, getDepositApi, referralusers } from "backendServices/ApiCalls";
import { Chip, IconButton, Tooltip, Grid } from "@mui/material";
import {
  FileCopy as FileCopyIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";

function Customers() {
  const [referralusersdata, setReferralUsersData]=useState([])
  const [loading, setLoading]=useState(true)

  const ReferralUsers =()=>{
    referralusers((response) => {
        setReferralUsersData(response?.data?.data?.entries)
        setLoading(false)
    }, (error) => {
        console.log(error?.response?.data);
        setLoading(true)
    })
} 

useEffect(()=>{
	ReferralUsers();
},[])

  const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };

  const rows = referralusersdata;

  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      dataGeneratorUniquenessEnabled: true,
      width: 80,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width:150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "current_balance",
      headerName: "Balance",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "roibalance",
      headerName: "ROI Balance",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
  ];

  return (
    <JumboDemoCard
      title={"Customers Detail"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithId}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
}

export default Customers;
