import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { Button, Grid } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";

const Supportfile = () => {
  const [supportFile, setSupportFile] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const navigate = useNavigate();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const Getmanagesupport = () => {
    postRequest(
      "/getsupportfile",
      "",
      (response) => {
        const processedData = response?.data?.data?.map((row, index) => ({
          ...row,
          index: index + 1,
        }));
        setSupportFile(processedData);
        setFileUrl(response?.data?.fileURL);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    Getmanagesupport();
  }, []);

  const handleView = (filename) => {
    const fullUrl = `${fileUrl}/${filename}`;
    window.open(fullUrl, "_blank");
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 60,
    },
    {
      field: "supporttype",
      headerName: "Support Type",
      width: 120,
      editable: true,
    },
    {
      field: "videolink",
      headerName: "Video Link",
      width: 110,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <>
          {params.row.supporttype === "Video Link" ? (
            // Render Copy button for Video Link
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                navigate("/video", {
                  state: { videolink: params.row.videolink },
                })
              }
            >
              View
            </Button>
          ) : params.row.supporttype === "PDF File" ? (
            // Render View button for PDF File
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleView(params.row.filename)}
            >
              View
            </Button>
          ) : (
            // Default rendering (you can adjust this based on your requirements)
            params.row.videolink
          )}
        </>
      ),
    },
    {
      field: "detail",
      headerName: "Detail",
      width: 300,
      editable: true,
      groupable: false,
      renderCell: (params) => (
        <Div
          dangerouslySetInnerHTML={{
            __html: params.row.detail.replace(/\\n/g, "").replace(/\\/g, ""),
          }}
        />
      ),
    },
    {
      field: "createdate",
      headerName: "CreateDate",
      width: 200,
    },
  ];

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <Grid container p={2} alignItems="center" justifyContent="center">
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Manage Support File"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          <Box sx={{ height: 400, width: 1 }} textAlign="right">
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={supportFile}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Supportfile;
