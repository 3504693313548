import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import PortfolioBalance from "../../../shared/metrics/PortfolioBalance";
import EarningExpenses from "../../../shared/metrics/EarningExpenses";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import {
  referralusers,
  lasttransactions,
  dashBoardApi,
  gettotalearning,
  getweeklybonus,
	gettotalreferrals,
} from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Div from "@jumbo/shared/Div/Div";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import { useNavigate } from "react-router-dom";

const Crypto = () => {
  const navigate = useNavigate();
  const [lasttransactionsdata, setLastTransactionsData] = useState([]);
  const [totalEarningData, setTotalEarning] = useState([]);
  const [weeklyBonusData, setWeeklyBonus] = useState([]);
  const [picturelink, setPictureLink] = useState([]);
  const [referralusersdata, setReferralUsersData] = useState([]);
  const [dashBoardUse, setDashBoardUse] = useState({
    levelBonus: 0,
    payout: 0,
    contractName: "",
    contractAmount: 0,
  });
  const [loader, setLoader] = useState(false);
  const { loginUserData, loading, handleOpen } = useContext(CustomProvider);
  let userData = loginUserData;

  if (userData.user_type === 'user') {
    navigate('/customer-dashboard')
  }

  const TransactionData = () => {
    lasttransactions(
      (response) => {
        setLastTransactionsData(response?.data?.data?.entries);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const dashBoardData = () => {
    setLoader(true);
    dashBoardApi(
      (response) => {
        setDashBoardUse(response?.data?.data);
        setLoader(false);
      },
      (error) => {
        setLoader(false);
        console.log(error?.response?.data);
      }
    );
  };

  const ReferralUsers = () => {
    referralusers(
      (response) => {
        setReferralUsersData(response?.data?.data?.entries);
        setPictureLink(response?.data?.data?.picturelink);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const totalEarning = () => {
    gettotalearning(
      (response) => {
        setTotalEarning(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const weeklyBonus = () => {
    getweeklybonus(
      (response) => {
        setWeeklyBonus(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    dashBoardData();
    ReferralUsers();
    TransactionData();
    totalEarning();
    weeklyBonus();
  }, []);

  if (loading || loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {userData?.user_type === "csc" ? (
          <>
            <Grid item xs={12} sm={6} lg={3}>
              <ObjectCountRevenue
                title={"Cash Account (AED)"}
                value={userData.current_balance}
                vertical={true}
                icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ObjectCountRevenue
                title={"ROI Account (AED)"}
                value={userData.roibalance}
                vertical={true}
                icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ObjectCountRevenue
                title={"Total Earning (AED)"}
                value={totalEarningData}
                vertical={true}
                icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ObjectCountRevenue
                title={"Bonus This Week (AED)"}
                value={weeklyBonusData}
                vertical={true}
                icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6} lg={4}>
              <ObjectCountRevenue
                title={"ROI Account (AED)"}
                value={userData.roibalance}
                vertical={true}
                icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <ObjectCountRevenue
                title={"Total Earning (AED)"}
                value={totalEarningData}
                vertical={true}
                icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <ObjectCountRevenue
                title={"Bonus This Week (AED)"}
                value={weeklyBonusData}
                vertical={true}
                icon={<AccountBalanceWalletRoundedIcon fontSize="large" />}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} lg={6}>
          <PortfolioBalance />
        </Grid>
        <Grid item xs={12} lg={6}>
          <EarningExpenses />
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={4}>
                {
                    loginUserData.status === 'pending' ?
                        <ObjectCountRevenue1 value={userData?.current_balance || 0} title='Buy Contract' color="secondary.main" icon={<ShoppingCartCheckoutRounded fontSize='large' />} vertical={true} />
                        :
                        <ObjectCountRevenue value={dashBoardUse?.contractAmount} title={dashBoardUse?.contractName} color="secondary.main" icon={<ShoppingCartCheckoutRounded fontSize='large' />} vertical={true} />
                }
            </Grid> */}

        {/* <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={userData?.current_balance || 0} title='E-Wallet' color="secondary.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large' />} vertical={true} />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={dashBoardUse?.levelBonus || 0} title='Level Bonus' color="secondary.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large' />} vertical={true} />
            </Grid> */}

        {/* <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={(dashBoardUse?.payout).toFixed(2) || 0} title='Total Payout' color="secondary.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large' />} vertical={true} />
            </Grid> */}

        {/* <Grid item xs={12} lg={6}>
                <Referralusers picturelink={picturelink} referralusersdata={referralusersdata} />
            </Grid> */}
        {/* <Grid item xs={12} lg={6}>
                <MarketingCampaign lasttransactionsdata={lasttransactionsdata} />
            </Grid> */}
      </Grid>
    </>
  );
};

export default Crypto;
