import React, { useState, useEffect } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div/Div';
import { getsettingsdata, payoutrequest, getPayoutData, postRequest } from 'backendServices/ApiCalls';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { useTranslation } from "react-i18next";

const Payout = () => {
  const { t } = useTranslation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: ""
  })

  const { loginUserData, setloginUserData,getUserData } = useContext(CustomProvider);
  const [settingsdata, setSettingsData] = useState([]);
  const [payoutData, setPayoutData] = useState(null);
  const [loader, setLoader] = useState(false);
  const userData = loginUserData
  console.log("userData", userData)

  const fetchsettingdata = () => {
    const params = {
      keynames: "'payout_fee', 'min_payout', 'payout_flat_fee'"
    };
    getsettingsdata(params, (response) => {
      setSettingsData(response?.data?.data);
    });
  };


  const payoutDataFun = () => {
    setLoader(true)
    getPayoutData((response) => {
      setPayoutData(response?.data?.data);
      setLoader(false)
    });
  };


  useEffect(() => {
    fetchsettingdata();
    payoutDataFun();
  }, [])

  const validationSchema = yup.object({

    // .min(settingsdata?.values[1]?.keyvalue, `Amount must be at least ${settingsdata?.values[1]?.keyvalue}`),
    current_balance: yup
      .number('Current Balance ')
      .required('Current balance is required'),
    bank_name: yup
      .string('Bank name')
      .required('Bank Name is required'),
    bank_account_number: yup
      .number('Bank account number')
      .required('Bank account number is required'),
    amount: yup
      .number('Enter withdrawal amount')
      .required('Withdrawal Amount is required'),
  });


  // const onSubmitForm = (amount, payoutaccount1, payoutaccount2, password, setSubmitting, resetForm) => {
    const onSubmitForm = (data,setSubmitting, resetForm) => {
    let params = {
      bankname: data.bank_name,
      accountnumber:data.bank_account_number,
      amount:data.amount
    }

    console.log("handleSubmit", data);
        postRequest(
            "/payoutrequest",
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // setOpen(false)
                    // GetallUsers();
                    getUserData();
                } else {
                    setSubmitting(false)
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status
                    })
                    // resetForm();

                }

            },
            (error) => {
                console.log(error?.response?.data);
                setSubmitting(false)

            }
        );
  }

  // if (loader) {
  //   return <Div
  //     sx={{
  //       display: 'flex',
  //       minWidth: 0,
  //       alignItems: 'center',
  //       alignContent: 'center',
  //       height: '100%',
  //     }}
  //   >
  //     <CircularProgress sx={{ m: '-40px auto 0' }} />
  //   </Div>
  // }


  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center" >
      {
        alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
      }

      <Grid item sm={5} >
        <JumboDemoCard title={'Request Withdrawal'}
          wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

          <Grid container>
            <Grid item md={12} lg={12} sm={12}>
              {
                payoutData !== 0 ? <Typography variant='p' color={'red'}>You can withdrawal minimum 200AED</Typography> : null
              }

            </Grid>
            <Grid item md={12} lg={12} sm={12}>
              <Formik
                enableReinitialize="true"
                validateOnChange={true}
                initialValues={{
                  // amount: '',
                  // payoutaccount1: 'USDT(TRC.20)',
                  // payoutaccount2: '',
                  // random: loginUserData.walletbalance || ''
                  current_balance: userData?.current_balance || "0",
                  bank_name: userData?.bankname || "",
                  bank_account_number: userData?.bankaccountnumber || "",
                  amount: ""
                }}

                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  console.log("formikData", data);
                  onSubmitForm(data,setSubmitting, resetForm);
                  // onSubmitForm(data.amount, data.payoutaccount1, data.payoutaccount2, data.password, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting }) => (
                  <Form style={{ textAlign: 'left' }} noValidate autoComplete='off'>
                      <Div sx={{ mt: 2, }}>
                        <JumboTextField
                          fullWidth
                          name="current_balance"
                          // label={'Your Balance is $' + userData.current_balance}
                          label="Current Balance"
                          type="number"
                          InputProps = {{
                            readOnly:true
                          }}
                        // disabled
                        /></Div>
                      <Div sx={{ mt: 3, }}>
                        <JumboTextField
                          fullWidth
                          name="bank_name"
                          label="Bank Name"
                          type="text"
                          InputProps = {{
                            readOnly:true
                          }}
                        /></Div>
                      <Div sx={{ mt: 3, }}>
                        <JumboTextField
                          fullWidth
                          name="bank_account_number"
                          label="Bank account number"
                          type="number"
                          InputProps = {{
                            readOnly:true
                          }}
                        // disabled={true}
                        /></Div>
                      <Div sx={{ mt: 3, mb: 2 }}>
                        <JumboTextField
                          fullWidth
                          name="amount"
                          label="Amount withdrawal"
                          type="number"
                        /></Div>

                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >Submit Request</LoadingButton>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>

        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Payout;