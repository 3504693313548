import React, { useEffect } from "react";
import List from "@mui/material/List";
import { Autocomplete, Box, Grid, TextField, Alert } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import { countries } from "app/pages/components/mui/AutoCompletes/data";
import { useContext } from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import * as yup from "yup";
import { postRequest } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Formik } from "formik";
import { Form } from "formik";

// const validationSchema = yup.object({
//     firstname: yup.string("Enter first name").required("first name is required"),
//     lastname: yup.string("Enter last name").required("last name is required"),
//     email: yup
//         .string("Enter email address")
//         .email("Invalid email address")
//         .required("Email is required"),
//     mobile: yup.string("Enter mobile number").required("Mobile is required"),
//     country: yup.string("select country").required("Country is required"),
//     bank_name: yup.string("Bank Name").required("Bank Name is required"),
//     bank_account_number: yup
//         .string("Bank account number")
//         .required("Bank account number is required"),
//     id_passport: yup.string("ID Passport").required("ID or Passport is required"),
// });

const About = () => {
    const [country, setCountry] = useState("");
    const [image, setImage] = useState(null);
    const { loginUserData, setloginUserData } = useContext(CustomProvider);
    let userData = loginUserData;
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [alertData, setalertData] = React.useState({
        show: false,
        message: "",
        variant: "",
    });

    const validationSchemas = {
        user: yup.object({
            firstname: yup.string("Enter first name").required("first name is required"),
            lastname: yup.string("Enter last name").required("last name is required"),
            email: yup
                .string("Enter email address")
                .email("Invalid email address")
                .required("Email is required"),
            mobile: yup.string("Enter mobile number").required("Mobile is required"),
            country: yup.string("select country").required("Country is required"),
        }),
        csc: yup.object({
            firstname: yup.string("Enter first name").required("first name is required"),
            lastname: yup.string("Enter last name").required("last name is required"),
            email: yup
                .string("Enter email address")
                .email("Invalid email address")
                .required("Email is required"),
            mobile: yup.string("Enter mobile number").required("Mobile is required"),
            country: yup.string("select country").required("Country is required"),
            bank_name: yup.string("Bank Name").required("Bank Name is required"),
            bank_account_number: yup
                .string("Bank account number")
                .required("Bank account number is required"),
            id_passport: yup.string("ID Passport").required("ID or Passport is required"),
        }),
    };

    const validationSchema = validationSchemas[userData?.user_type] || validationSchemas.user;

    const initialUserValues = {
        firstname: userData.firstname || "",
        lastname: userData.lastname || "",
        email: userData.email || "",
        mobile: userData.mobile || "",
        country: userData.country || "",
    };

    const initialCSCValues = {
        ...initialUserValues,
        bank_name: userData.bankname || "",
        bank_account_number: userData.bankaccountnumber || "",
        id_passport: "",
    };

    const initialValues = userData?.user_type === "csc" ? initialCSCValues : initialUserValues;


    const handleUpload = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            console.log("Selected File:", selectedFile);
            if (selectedFile.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Data = reader.result; // Extract base64 encoded string
                    if (base64Data) {
                        setImage(base64Data);
                        console.log("Image loaded successfully!", base64Data);
                    } else {
                        console.log("Error loading image.");
                    }
                };
                reader.readAsDataURL(selectedFile); // Read the selected file as data URL
            } else {
                setalertData({
                    show: true,
                    message: "Invalid file type. Please select an image file.",
                    variant: "error",
                });
            }
        }
    };

    const onSubmitForm = async (data, setSubmitting) => {
        let params = {
            id: userData.userid,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            mobile: data.mobile,
            country: country,
            bankname: data.bank_name,
            bankaccountnumber: data.bank_account_number,
            idcardimage: image,
        };

        postRequest(
            "/updateuserdata",
            params,
            (response) => {
                if (response?.data?.status === "success") {
                    setSubmitting(false);
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status,
                        });
                        // setloginUserData();
                        // setOpenUpdate(false);
                        // nav('/profile');
                } else {
                    setSubmitting(false);
                    setalertData({
                        show: true,
                        message: response?.data?.message,
                        variant: response?.data?.status,
                    });
                    // setOpenUpdate(false);
                }
            },
            (error) => {
                console.log(error?.response?.data);
                setSubmitting(false);
            }
        );
    };
    useEffect(() => {
        if (userData && userData.country) {
            const country = countries.find(
                (country) =>
                    country.label.toLowerCase() === userData.country.toLowerCase()
            );
            setSelectedCountry(country);
        }
    }, [userData, countries]);
    if (userData.firstname === "") {
        return <div>laoding</div>;
    }

    return (
        <JumboCardQuick title={"Update Profile"}>
            {/* {userData?.profileupdated === "no" && (
                <Box sx={{ mb: 5 }}>
                    <Alert severity="warning">
                        First you need to fill your profile form
                    </Alert>
                </Box>
            )} */}
            {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
            <List disablePadding>
                <Formik
                    validateOnChange={true}
                    enableReinitialize="true"
                    initialValues={{
                        firstname: userData.firstname || "",
                        lastname: userData.lastname || "",
                        email: userData.email || "",
                        mobile: userData.mobile || "",
                        country: userData.country || "",
                        bank_name: userData.bankname || "",
                        bank_account_number: userData.bankaccountnumber || "",
                        id_passport: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting }) => {
                        setSubmitting(true);
                        onSubmitForm(data, setSubmitting);
                        console.log("formikData", data);
                    }}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        name="firstname"
                                        label="First Name"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <JumboTextField
                                        fullWidth
                                        label="Last Name"
                                        name="lastname"
                                        type="text"
                                    />
                                </Grid>

                                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                                    <JumboTextField
                                        fullWidth
                                        label="Email"
                                        name="email"
                                        type="email"
                                    />
                                </Grid>

                                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                                    <JumboTextField
                                        fullWidth
                                        label="Mobile"
                                        name="mobile"
                                        type="tel"
                                    />
                                </Grid>

                                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                                    <Autocomplete
                                        sx={{ width: "100%" }}
                                        component={TextField}
                                        value={selectedCountry}
                                        onInputChange={(event, newCountry) => {
                                            setCountry(newCountry);
                                        }}
                                        onChange={(event, newCountry) => {
                                            setSelectedCountry(newCountry);
                                        }}
                                        id="country-select-demo"
                                        options={countries}
                                        autoHighlight
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                {...props}
                                            >
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                {option.label} ({option.code}) +{option.phone}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <JumboTextField
                                                name="country"
                                                fullWidth
                                                {...params}
                                                label="Country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {userData?.user_type === "csc" && (
                                    <>
                                        <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                                            <JumboTextField
                                                fullWidth
                                                label="Bank name"
                                                name="bank_name"
                                                type="text"
                                            />
                                        </Grid>

                                        <Grid item sm={12} sx={{ width: { xs: "100%" } }}>
                                            <JumboTextField
                                                fullWidth
                                                label="Bank account number"
                                                name="bank_account_number"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item sm={12} sx={{ width: { xs: "100%" } }}>
                                            <label>Clear copy of ID/passport</label>
                                            <TextField
                                                fullWidth
                                                // label="Clear copy of ID/passport "
                                                name="id_passport"
                                                onChange={(event) => {
                                                    handleUpload(event, "id_passport");
                                                    setFieldValue(
                                                        "id_passport",
                                                        event.currentTarget.files[0]
                                                    );
                                                }}
                                                type="file"
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Grid
                                    item
                                    sm={12}
                                    sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                                >
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isSubmitting}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </List>
        </JumboCardQuick>
    );
};

export default About;
