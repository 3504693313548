import React from "react";
import {
  Alert,
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { Link as MyLink, useNavigate } from "react-router-dom";
import useApp from "app/hooks/useApp";
import { requestPasswordReset } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { Twitter } from "@mui/icons-material";

const validationSchema = yup.object({
  email: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotPassword = () => {
  var { companyName } = useApp();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [serverError, setServerError] = React.useState(null);

  const resetPassword = (email, setSubmitting) => {
    requestPasswordReset(
      email,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setServerError(response?.data?.message);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",
            background: `#0267a0 url(${getAssetPath(
              `${ASSET_IMAGES}/widgets/login-page.jpg`,
              "640x428"
            )}) no-repeat center`,
            backgroundSize: "cover",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: "rgb(27 32 62 / 85%)",
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                Forgot Password
              </Typography>
              {/* <Typography variant={""} mb={2}>
                <h4>Quote of the day</h4>
                <p>{randomQuote}</p>
              </Typography>
              <Typography variant={"body1"}>
                <MyLink
                  style={{ color: "yellow" }}
                  to={"/forget-password"}
                  color={"inherit"}
                  underline={"none"}
                >
                  Forgot your password? Recover Now
                </MyLink>
              </Typography> */}
            </Div>

            <Div sx={{ mt: "auto", textAlign: "center" }}>
              <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
                <img
                  src={`${ASSET_IMAGES}/logo.png`}
                  style={{ width: "150px" }}
                  alt=""
                />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          {/* {validToken === true ? (
            <Alert severity="success">
              Your account is verified successfully. You can login to your
              account now{" "}
            </Alert>
          ) : validToken === false ? (
            <Alert severity="error">Invalid verification link. </Alert>
          ) : null} */}
          <Formik
            validateOnChange={true}
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              resetPassword(data.email, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                {serverError?.message && (
                  <Alert severity="error">{serverError.message}</Alert>
                )}
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField
                    fullWidth
                    name="email"
                    label="Email"
                    type="email"
                  />
                </Div>

                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
                <Div sx={{ mb: 2 }}>
                  <Typography variant="p">
                    Don't want to reset?{" "}
                    <MyLink
                      style={{ color: "yellow" }}
                      component="Link"
                      to="/login"
                    >
                      Login
                    </MyLink>
                  </Typography>
                </Div>
                {/* {disableSmLogin && (
                  <React.Fragment>
                    <Typography variant={"body1"} mb={2}>
                      Or sign in with
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      mb={1}
                    >
                      <IconButton
                        sx={{
                          bgcolor: "#385196",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#385196",
                          },
                        }}
                        aria-label="Facebook"
                      >
                        <Facebook fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          bgcolor: "#00a8ff",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#00a8ff",
                          },
                        }}
                        aria-label="Twitter"
                      >
                        <Twitter fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          bgcolor: "#23272b",
                          color: "common.white",
                          p: (theme) => theme.spacing(1.25),

                          "&:hover": {
                            backgroundColor: "#23272b",
                          },
                        }}
                        aria-label="Twitter"
                      >
                        <Google fontSize="small" />
                      </IconButton>
                    </Stack>
                  </React.Fragment>
                )} */}
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default ForgotPassword;
