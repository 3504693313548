import React from "react";

import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import GroupsIcon from "@mui/icons-material/Groups";
import AddCardIcon from "@mui/icons-material/AddCard";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {
  ShoppingBagOutlined,
  ShoppingBasket,
  ShoppingCartCheckoutRounded,
} from "@mui/icons-material";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ShopIcon from "@mui/icons-material/Shop";

const menus = [
  {
    label: "sidebar.menu.home",
    type: "section",
    children: [
      {
        uri: "/dashboard",
        label: "sidebar.menuItem.dashboard",
        type: "nav-item",
        icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/profile",
        label: "sidebar.menuItem.profile",
        type: "nav-item",
        icon: <AssignmentIndIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/roishop",
        label: "sidebar.menuItem.roishop",
        type: "nav-item",
        icon: <ShopIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/payout",
        label: "sidebar.menuItem.rpayout",
        type: "nav-item",
        icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/referrals",
        label: "sidebar.menuItem.referrals",
        type: "nav-item",
        icon: <GroupsIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //     uri: "/deposit",
      //     label: 'sidebar.menuItem.deposit',
      //     type: "nav-item",
      //     icon: <AddCardIcon sx={{ fontSize: 20 }} />
      // },
      // {
      //     uri: "/contracts",
      //     label: 'Contracts',
      //     type: "nav-item",
      //     icon: <GraphicEqIcon sx={{ fontSize: 20 }} />
      // },
      // {
      //     uri: "/buy-contract",
      //     label: 'Buy Contract',
      //     type: "nav-item",
      //     icon: <ShoppingCartCheckoutRounded sx={{ fontSize: 20 }} />
      // },

      {
        label: "sidebar.menuItem.summery",
        type: "collapsible",
        icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/buy-product-summary",
            label: "sidebar.menuItem.productsummary",
            type: "nav-item",
          },
					{
            uri: "/cash-bonus-summary",
            label: "sidebar.menuItem.paymentsummary",
            type: "nav-item",
          },
          {
            uri: "/rio-bonus-summary",
            label: "sidebar.menuItem.rio-bonus-summary",
            type: "nav-item",
          },
          {
            uri: "/withdrawal-summary",
            label: "Widraw Summary",
            type: "nav-item",
          },
          // {
          //     uri: "/referral-bonus",
          //     label: "sidebar.menuItem.referralbonus",
          //     type: "nav-item"
          // },
          // {
          //     uri: "/earning-summary",
          //     label: "sidebar.menuItem.earning-summary",
          //     type: "nav-item"
          // },
        ],
      },

      {
        uri: "/treeview",
        label: "sidebar.menuItem.customers",
        type: "nav-item",
        icon: <ReduceCapacityIcon sx={{ fontSize: 20 }} />,
      },

      // {
      //     uri: "/news",
      //     label: 'sidebar.menuItem.news',
      //     type: "nav-item",
      //     icon: <NewspaperIcon sx={{ fontSize: 20 }} />
      // },
      {
        uri: "/support-files",
        label: "sidebar.menuItem.support-files",
        type: "nav-item",
        icon: <NewspaperIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/logout",
        label: "sidebar.menuItem.logout",
        type: "nav-item",
        icon: <LogoutIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
