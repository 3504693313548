import React from "react";
import CardHeader from "@mui/material/CardHeader";
import {
  Alert,
  Backdrop,
  Card,
  CardActions,
  CardContent,
  Fade,
  IconButton,
  LinearProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  gettotalreferrals,
  gettotalreferralsmonthly,
  gettotalreferralsweekly,
} from "backendServices/ApiCalls";
import { useContext } from "react";
import * as yup from "yup";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Div from "@jumbo/shared/Div";
import { Form, Formik } from "formik";
import { ContentCopy } from "@mui/icons-material";

const validationSchema = yup.object({
  fname: yup
    .string("Enter recipient first name")
    .required("first name is required"),
  lname: yup
    .string("Enter recipient last name")
    .required("last name is required"),
  email: yup
    .string("Enter recipient email address")
    .required("email is required"),
  phone: yup
    .string("Enter recipient phone number")
    .required("phone number is required"),
  message: yup.string("Enter message").required("message is required"),
});

const PortfolioBalance = (props) => {
  const {
    loginUserData,
    handleOpen,
    handleClose,
    open,
    alertData,
    setalertData,
  } = useContext(CustomProvider);
  const [totalReferralsData, setTotalReferrals] = useState([]);
  const [weeklyReferrals, setWeeklyReferrals] = useState([]);
  const [monthlyReferrals, setMonthlyReferrals] = useState([]);

  const referrallink = loginUserData?.referrallink;

  const handleCopy = () => {
    navigator.clipboard.writeText(referrallink);
    setalertData({
      show: true,
      message: "Referral link copied to clipboard",
      variant: "success",
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const { t } = useTranslation();
  const date = new Date(); //Get the day of the week of particular date mentioned by the user
  var day = date.getDay();
  var day1 = date.getDate();
  day = (day / 7) * 100;
  day1 = (day1 / 30) * 100;
  const dayp = day + "%";
  const day1p = day1 + "%";

  const totalReferrals = () => {
    gettotalreferrals(
      (response) => {
        setTotalReferrals(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const totalReferralsWeekly = () => {
    gettotalreferralsweekly(
      (response) => {
        setWeeklyReferrals(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const totalReferralsMonthly = () => {
    gettotalreferralsmonthly(
      (response) => {
        setMonthlyReferrals(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    totalReferrals();
    totalReferralsWeekly();
    totalReferralsMonthly();
  }, []);

  return (
    <Card>
      <CardHeader title={"Referral Users"} />
      <CardContent sx={{ pt: 2 }}>
        <Stack direction={"row"} spacing={3}>
          <Grid item xs={6}>
            <Typography variant={"h2"}></Typography>
            <Typography variant={"body1"} sx={{ mb: 3 }}>
              Total Referrals: {totalReferralsData[0]?.userCount}
            </Typography>
            <Stack direction={"row"} spacing={1}>
              <Button
                size="small"
                variant={"contained"}
                sx={{ backgroundColor: "#C7A75A" }}
                onClick={handleOpen}
                endIcon={<PersonAddAltIcon />}
              >
                Invite
              </Button>
              <Button
                size="small"
                variant={"contained"}
                sx={{ backgroundColor: "#C7A75A" }}
                component={Link}
                to={"/referrals"}
              >
                Summary
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant={"h5"} color={"text.secondary"} mb={2}>
              Time Period
            </Typography>
            <Typography variant={"h6"} color={"text.primary"}>
              This week
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={"span"}
                color={"text.secondary"}
              >
                {weeklyReferrals[0]?.weeklyUserCount}
              </Typography>
            </Typography>
            <LinearProgress
              variant={"determinate"}
              color={"success"}
              value={day}
              sx={{
                width: { dayp },
                borderRadius: 4,
                height: 5,
                mb: 2,
                backgroundColor: "#E9EEEF",
              }}
            />
            <Typography variant={"h6"} color={"text.primary"}>
              This Month
              <Typography
                sx={{ borderLeft: 1, ml: 1, pl: 1 }}
                component={"span"}
                color={"text.secondary"}
              >
                {monthlyReferrals[0]?.monthlyUserCount}
              </Typography>
            </Typography>
            <LinearProgress
              variant={"determinate"}
              color={"warning"}
              value={day1}
              sx={{
                width: { day1p },
                borderRadius: 4,
                height: 5,
                backgroundColor: "#E9EEEF",
              }}
            />
          </Grid>
        </Stack>
      </CardContent>
      {/* <CardActions sx={{ pt: 0.5, pb: 2 }}>
        <Button startIcon={<AddIcon />} size="small">
          View Report
        </Button>
      </CardActions> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={open}>
          <Div sx={style} alignItems="center">
            <Typography id="transition-modal-title" variant="h4" component="h2">
              Send Invitation Link
              <CloseIcon
                onClick={handleClose}
                sx={{
                  float: "right",
                  color: "white",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Typography>
            {alertData.show && (
              <Alert severity="success">{alertData.message}</Alert>
            )}
            <center>
              <Formik
                validateOnChange={true}
                initialValues={{
                  message: "",
                  phone: "",
                  email: "",
                  lname: "",
                  fname: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  // onSubmitForm(data, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 2 }}>
                      <TextField
                        sx={{ width: "100%" }}
                        label={"Invite users with this link"}
                        type="text"
                        disabled
                        value={referrallink}
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <IconButton sx={{ color: "white" }}>
                              <ContentCopy onClick={handleCopy} />
                            </IconButton>
                          ),
                        }}
                      />
                    </Div>
                  </Form>
                )}
              </Formik>
            </center>
          </Div>
        </Fade>
      </Modal>
    </Card>
  );
};

export default PortfolioBalance;
