import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  postRequest,
} from "backendServices/ApiCalls";
import {
  Grid,
} from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div";
import { Link } from "react-router-dom";

const DepositReport = () => {
  const [supportFile, setSupportFile] = useState([]);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  
 
  const GetBonusSummery = () => {
    postRequest(
      "/getcashbonussummary",
      "",
      (response) => {
        console.log("responseBonus", response)
        const processedData = response?.data?.data?.map((row, index) => ({
          ...row,
          index: index + 1,
          id: index + 1,
        }));
        // setCkeditorContent(response?.data?.data?.detail)
        setSupportFile(processedData);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetBonusSummery();
  }, []);
 
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
    },
    {
      field: "username",
      headerName: "Sender",
      width: 100,
      editable: true,
    },
    {
      field: "cashbonus",
      headerName: "Cash Bonus",
      width: 100,
      editable: true,
      groupable: false,
      
    },
    {
      field: "details",
      headerName: "Details",
      width: 250,
      editable: true,
      groupable: false,
      
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      editable: true,
      groupable: false,
      
    },
    {
      field: "createdat",
      headerName: "Date",
      width: 200,
    },
  ];

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  
  return (
    <Grid container p={2} alignItems="center" justifyContent="center">
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Cash Bonus Summary"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          <Box sx={{ height: 400, width: 1 }} textAlign="right">
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={supportFile}
              // getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default DepositReport;
