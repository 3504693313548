import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import Div from '@jumbo/shared/Div';
import { Grid } from '@mui/material';
import React from 'react'
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom'


const Video = () => {
    const location = useLocation();

    let videolink = location.state.videolink;
    console.log("videolink", videolink)
    return (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item sm={8} xs={12}>
                <JumboDemoCard
                    title={"Manage Support File"}
                    wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
                >
                     <Div sx={{ margin: "auto", display: "flex", justifyContent: "center" }}>
                              <ReactPlayer minWidth="100%"
                                minHeight="100%" controls url={videolink} />
                            </Div>
                </JumboDemoCard>
            </Grid>
        </Grid>
    )
}

export default Video
