import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";

const Roishopcard = ({ productData, imageUrl }) => {
  const navigate = useNavigate();

  return (
    <>
      <h1>ROI SHOP</h1>
      <Grid container spacing={2}>
        {productData?.map((value, index) => (
          <Grid item sm={4} xs={12} key={index}>
            <JumboCardQuick
              sx={{
                textAlign: "center",
                position: "relative",
                minHeight: "50vh",
              }}
            >
              <img
                src={`${imageUrl}${value?.image}`}
                style={{
                  margin: "0 auto",
                  width: "75%",
                  minHeight: 230,
                  objectFit: "cover",
                  marginBottom: "20px",
                }}
                alt="Product Img"
              />
              <Div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 0,
                }}
              >
                <Div
                  style={{
                    width: "80%",
                    bottom: 30,
                    left: 0,
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "wrap",
                  }}
                >
                  <Typography variant={"h3"}>{value.title}</Typography>
                </Div>
                <Div style={{ paddingRight: 4, width: "35%" }}>
                  <Typography variant={"h5"}>{value.price_1_month} AED</Typography>
                </Div>
              </Div>
              <Div style={{ marginBottom: "10px", textAlign: "left" }}>
                <p style={{ margin: 0 }}>
                  Subscription Period: {value.subscriptionperiod}
                </p>
              </Div>
              {/* <Div style={{ marginBottom: '10px', textAlign: 'left' }}>
                            <p style={{ margin: 0 }}>Detail: </p>
                        </Div>
                        <Div style={{ marginBottom: '10px', textAlign: 'left' }}>
                            <p style={{ margin: 0 }}>Subscription Period start here </p>
                        </Div> */}

              <Div style={{ bottom: 0, right: 3, textAlign: "center" }}>
                <Button
                  startIcon={<ShoppingCartOutlinedIcon />}
                  variant={"contained"}
                  onClick={() =>
                    navigate("/roi-shop-checkout", {
                      state: {
                        title: value?.title,
                        product_id: value?.id,
                        imageUrl: imageUrl,
                        imageName: value?.image,
                        price1: value?.price_1_month,
                        price2: value?.price_3_month,
                        price3: value?.price_6_month,
                        detail: value?.detail,
                      },
                    })
                  }
                >
                  Buy Now
                </Button>
              </Div>
            </JumboCardQuick>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Roishopcard;
