import React from "react";
import EarningExpensesChart from "./EarningExpensesChart";
import { Card, CardContent, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Div from "@jumbo/shared/Div";
import { useEffect } from "react";
import { gettotalearning, gettotalexpenses } from "backendServices/ApiCalls";
import { useState } from "react";

const EarningExpenses = () => {
  const [activeChart, setActiveChart] = React.useState("expense");
	const [totalEarningData, setTotalEarning] = useState([]);
	const [totalExpensesData, setTotalExpenses] = useState([]);

	const totalEarning = () => {
    gettotalearning(
      (response) => {
        setTotalEarning(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

	const totalExpenses = () => {
    gettotalexpenses(
      (response) => {
        setTotalExpenses(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

	useEffect(() => {
    totalEarning();
		totalExpenses();
  }, []);

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Stack direction={"row"} spacing={3}>
          <Div>
            <Typography variant={"h3"} fontWeight={"500"} mb={0.5}>
              {totalEarningData[0]?.totalRoibonus} AED
            </Typography>
            <Typography variant={"body1"} mb={2}>
              Earning
            </Typography>
          </Div>
          <Div>
            <Typography variant={"h3"} fontWeight={"500"} mb={0.5}>
              {totalExpensesData[0]?.totalExpenses} AED
            </Typography>
            <Typography variant={"body1"} mb={2}>
              Expenses
            </Typography>
          </Div>
        </Stack>
        <Stack direction={"row"} spacing={1}>
          <Button
            variant={activeChart === "earning" ? "contained" : "outlined"}
            size={"small"}
            sx={{ color: "white", background: "#C7A75A" }}
            onClick={() => setActiveChart("earning")}
          >
            Earning
          </Button>
          <Button
            variant={activeChart === "expense" ? "contained" : "outlined"}
            disableElevation
            sx={{ color: "white", background: "#C7A75A" }}
            size={"small"}
            onClick={() => setActiveChart("expense")}
          >
            Expenses
          </Button>
        </Stack>
      </CardContent>
      <EarningExpensesChart activeChart={activeChart} />
    </Card>
  );
};

export default EarningExpenses;
