import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Button from "@mui/material/Button";
// import {products} from "../../../data";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { products } from 'app/shared/widgets/GoogleNest/data';
import Roishopcard from './Roishopcard';
import { postRequest } from 'backendServices/ApiCalls';

const Roishop = () => {

    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [imageUrl, setImageUrl] = React.useState();
    const [productData, setProductData] = useState([]);
    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setCurrentSlide({ currentSlide: index });
        }
    };
    console.log("imageUrl", imageUrl);
    console.log("productData",productData)
    const GetProductData = () => {
        postRequest(
            '/getproducts',
            "",
            (response) => {
                console.log('response', response)
                setProductData(response?.data?.data);
                setImageUrl(response?.data?.imageURL);
                if (response?.data?.data?.status === "success") {
                    console.log("response get Successfully");
                }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );

    }

    useEffect(() => {
        GetProductData();
    }, [])
   
    return (
        <Box>
            <Roishopcard  productData={productData} imageUrl={imageUrl}/>
        </Box>
    );
};

export default Roishop;
