import React, { useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  List,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useContext } from "react";
import { postRequest } from "backendServices/ApiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

const validationSchema = yup.object({
  firstname: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().required("Phone is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  zipcode: yup.number().required("Zipcode is required"),
  agreed: yup.boolean().oneOf([true], "You must agree to the purchase policy"),
});

const Roishopcheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isEnabledSilent, setisEnabledSilent] = useState("");
  const [isEnabledGeneral, setisEnabledGeneral] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { loginUserData, loading, setCartItems } =
    useContext(CustomProvider);
  const product_id = location.state.product_id;
  const title = location.state.title;
  const price1 = location.state.price1;
  const price2 = location.state.price2;
  const price3 = location.state.price3;
  const imageName = location.state.imageName;
  const imageUrl = location.state.imageUrl;
  const detail = location.state.detail;
  const [selectMthod, setSelectMthod] = React.useState("");
  const [selectSubscription, setSelectSubscription] = React.useState("1 Month");
  const [isAgreed, setIsAgreed] = useState(false);
  const [agreedStatus, setAgreedStatus] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState("");
  console.log("data", data);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const image = `${imageUrl}${imageName}`;

  let productPrice = '';
  if (selectSubscription === '1 Month') {
    productPrice = price1;
  } else if (selectSubscription === '3 Month') {
    productPrice = price2;
  } else if (selectSubscription === '6 Month') {
    productPrice = price3;
  }

  const handleOpen = (data) => {
    if (!isAgreed) {
      setAgreedStatus(true);
      return;
    }
    setData(data);
    setOpen(true);
  };

  const handleSubmit = async () => {
    if (!isAgreed) {
      setIsAgreed(true); // Update isAgreed state instead of setting agreedStatus directly
      return;
    }
    if (selectMthod === "Stripe") {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        setalertData({
          show: true,
          message: "Please enter card information",
          variant: "error",
        });
        return;
      }
      let type = "";
      if (isEnabledSilent === "1") {
        type = "inintial silent auction";
      } else if (isEnabledGeneral === "1") {
        type = "general drop auction";
      }
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      let params = {
        paymentMethodId: result?.paymentMethod?.id,
        amount: productPrice, // Replace with your desired amount
      };
      await postRequest(
        "/create-payment-intent",
        params,
        async (response) => {
          let p = {
            userid: loginUserData.userid,
            productid: product_id,
            price: productPrice,
            address: data.address,
            city: data.city,
            zipcode: data.zipcode,
            method: selectMthod,
          };
          await postRequest(
            "/buymembership",
            p,
            async (response) => {
              await setalertData({
                show: true,
                message: response?.data?.message,
                variant: "success",
              });
              setCartItems([]);
              navigate("/roishop");
            },
            (error) => {
              setalertData({
                show: true,
                message: response?.data?.message,
                variant: "error",
              });
              setalertData({
                show: true,
                message: error?.message,
                variant: "error",
              });
            }
          );
        },
        (error) => {
          // setSubmitting(false);
          setalertData({
            show: true,
            message: error?.message,
            variant: "error",
          });
          console.error(error);
        }
      );
    } else if (selectMthod === "ROI Balance") {
      let params = {
        userid: loginUserData.userid,
        productid: product_id,
        price: productPrice,
        address: data.address,
        city: data.city,
        zipcode: data.zipcode,
        method: selectMthod,
      };

      postRequest(
        "/buymembership",
        params,
        (response) => {
          if (response?.data?.status === "success") {
            // setSubmitting(false);
            setAgreedStatus(false);
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: response?.data?.status,
            });
            navigate("/roishop");
          } else {
            // setSubmitting(false);
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: response?.data?.status,
            });
          }
        },
        (error) => {
          console.log(error?.response?.data);
          // setSubmitting(false);
        }
      );
    }
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <JumboCardQuick title={"Product Checkout"}>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <List disablePadding sx={{ mb: 2 }}>
        <Formik
          validateOnChange={true}
          initialValues={{
            firstname: loginUserData?.firstname,
            email: loginUserData?.email,
            phone: loginUserData?.mobile,
            last_name: loginUserData?.lastname,
            address: "",
            city: "",
            zipcode: "",
            agreed: "",
            roibalance: loginUserData?.roibalance || "0",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting, resetForm }) => {
            // setSubmitting(true);
            // handleSubmit(data, setSubmitting, resetForm);
            handleOpen(data);
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                {/* Left Side - TextFields */}
                <Grid item sm={6} xs={12}>
                  <Grid container spacing={2} paddingBottom={2}>
                    <Grid item sm={6} xs={12}>
                      <JumboTextField
                        fullWidth
                        label="First Name"
                        name="firstname"
                        variant="outlined"
                        type="text"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <JumboTextField
                        fullWidth
                        label="Last Name"
                        name="last_name"
                        variant="outlined"
                        type="text"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <JumboTextField
                        fullWidth
                        label="Email"
                        name="email"
                        variant="outlined"
                        type="email"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <JumboTextField
                        fullWidth
                        label="Phone"
                        name="phone"
                        variant="outlined"
                        type="tell"
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <JumboTextField
                        fullWidth
                        label="Address"
                        name="address"
                        variant="outlined"
                        type="text"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <JumboTextField
                        fullWidth
                        label="City"
                        name="city"
                        variant="outlined"
                        type="text"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <JumboTextField
                        fullWidth
                        label="Zipcode"
                        name="zipcode"
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel id="demo-simple-select-helper-label">
                         Select Subscription Period
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectSubscription}
                          label="Age"
                          onChange={(event) =>
                            setSelectSubscription(event.target.value)
                          }
                        >
                          <MenuItem value={"1 Month"}>1 Month</MenuItem>
                          <MenuItem value={"3 Month"}>3 Month</MenuItem>
                          <MenuItem value={"6 Month"}>6 Month</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Payment Method
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={selectMthod}
                          label="Age"
                          onChange={(event) =>
                            setSelectMthod(event.target.value)
                          }
                        >
                          <MenuItem value={"Stripe"}>Credit Card</MenuItem>
                          <MenuItem value={"ROI Balance"}>ROI Balance</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {selectMthod === "Stripe" ? (
                        <CardElement />
                      ) : selectMthod === "ROI Balance" ? (
                        <JumboTextField
                          fullWidth
                          label="ROI Balance"
                          name="roibalance"
                          variant="outlined"
                          type="text"
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="agreed"
                            required
                            checked={isAgreed} // Set the checked state of the checkbox
                            onChange={(e) => setIsAgreed(e.target.checked)} // Update the state of the checkbox
                          />
                        }
                        label={
                          <span>
                            I agreed with{" "}
                            <a
                              href="https://backend.roiwater.ae/documents/Purchase-policy.pdf"
                              style={{ color: "yellow" }}
                              target="_blank"
                            >
                              Purchase Policy
                            </a>
                          </span>
                        }
                      />
                      {agreedStatus === true ? (
                        <span style={{ color: "red" }}>
                          You must agree to the purchase policy
                        </span>
                      ) : agreedStatus === false ? (
                        ""
                      ) : null}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        // loading={isSubmitting}
                      >
                        Buy Now
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Right Side - Image */}
                <Grid container padding={2} sm={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: 2,
                      backgroundColor: "#394d7d",
                    }}
                  >
                    <Grid item sm={6} xs={12}>
                      <img
                        src={image}
                        alt="Product"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography sx={{ fontSize: "22px", pb: 3, pl: 2 }}>
                        {title}
                      </Typography>
                      <Typography variant="body1" sx={{ pl: 2 }}>
                        Detail: {selectSubscription + " Subscription"}
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography sx={{ fontSize: "22px", pl: 2 }}>
                        {" "}
                        Product Summery
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "18px",
                          pt: 2,
                          pl: 2,
                          overflowWrap: "break-word",
                        }}
                      >
                        Price:{" "}
                        <span style={{ float: "right" }}>{productPrice}AED</span>
                      </Typography>
                      <hr />
                      <Typography
                        sx={{
                          fontSize: "18px",
                          pt: 2,
                          pl: 2,
                          overflowWrap: "break-word",
                        }}
                      >
                        Total:{" "}
                        <span style={{ float: "right" }}>{productPrice}AED</span>
                      </Typography>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </List>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm your Payment"}
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            Full Name :{" "}
            <span style={{ color: "white" }}>
              {" "}
              {data.firstname + " " + data.last_name}{" "}
            </span>{" "}
            <br />
            Email : <span style={{ color: "white" }}>{data.email}</span> <br />
            Mobile : <span style={{ color: "white" }}>{data.phone}</span> <br />
            Address : <span style={{ color: "white" }}>
              {data.address}
            </span>{" "}
            <br />
            City : <span style={{ color: "white" }}>{data.city}</span> <br />
            ZipCode : <span style={{ color: "white" }}>
              {data.zipcode}
            </span>{" "}
            <br />
            <Divider sx={{ mt: 2 }} />
            Product Title : <span style={{ color: "white" }}>{title}</span>{" "}
            <br />
            Product Detail : <span style={{ color: "white" }}>
              {detail}
            </span>{" "}
            <br />
            Product Price : <span style={{ color: "white" }}>
              {productPrice} AED
            </span>{" "}
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Disagree</Button>
          <Button onClick={handleSubmit} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </JumboCardQuick>
  );
};

export default Roishopcheckout;
