import { getmonthlyearning, getmonthlyexpenses } from "backendServices/ApiCalls";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
// import { crypto } from "./data";

const EarningExpensesChart = ({ activeChart }) => {
  const [lasttransactionsdata, setLastTransactionsData] = useState([]);
  const [earningData, setEarningData] = useState([]);
  const topChart = activeChart;
  const bottomChart = activeChart === "earning" ? "expense" : "earning";
  const topChartColor = activeChart === "earning" ? "#F43B86" : "#2D46B9";
  const bottomChartColor = activeChart === "earning" ? "#2D46B9" : "#F43B86";

  const TransactionData = () => {
    getmonthlyexpenses(
      (response) => {
        setLastTransactionsData(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    TransactionData();
  }, []);


  const crypto = {
    revenueSummary: [
      { month: 'Jan', earning: `${earningData[0]?.totalEarning}`, expense: `${lasttransactionsdata[0]?.totalExpenses}` },
      { month: 'Feb', earning: `${earningData[1]?.totalEarning}`, expense: `${lasttransactionsdata[1]?.totalExpenses}` },
      { month: 'Mar', earning: `${earningData[2]?.totalEarning}`, expense: `${lasttransactionsdata[2]?.totalExpenses}` },
      { month: 'Apr', earning: `${earningData[3]?.totalEarning}`, expense: `${lasttransactionsdata[3]?.totalExpenses}` },
      { month: 'May', earning: `${earningData[4]?.totalEarning}`, expense: `${lasttransactionsdata[4]?.totalExpenses}` },
      { month: 'Jun', earning: `${earningData[5]?.totalEarning}`, expense: `${lasttransactionsdata[5]?.totalExpenses}` },
      { month: 'Jul', earning: `${earningData[6]?.totalEarning}`, expense: `${lasttransactionsdata[6]?.totalExpenses}` },
      { month: 'Aug', earning: `${earningData[7]?.totalEarning}`, expense: `${lasttransactionsdata[7]?.totalExpenses}` },
      { month: 'Sep', earning: `${earningData[8]?.totalEarning}`, expense: `${lasttransactionsdata[8]?.totalExpenses}` },
      { month: 'Oct', earning: `${earningData[9]?.totalEarning}`, expense: `${lasttransactionsdata[9]?.totalExpenses}` },
      { month: 'Nov', earning: `${earningData[10]?.totalEarning}`, expense: `${lasttransactionsdata[10]?.totalExpenses}` },
      { month: 'Dec', earning: `${earningData[11]?.totalEarning}`, expense: `${lasttransactionsdata[11]?.totalExpenses}` },
    ],
  };

  return (
    <ResponsiveContainer height={152}>
      <AreaChart
        data={lasttransactionsdata}
        margin={{ top: 0, right: 20, left: 20, bottom: 0 }}
      >
        <Tooltip
          content={({ active, label, payload }) => {
            return active ? (
              <div style={{ color: "#fff" }}>
                {payload.map((row, index) => {
                  return (
                    <div key={index} className={"mb-1"}>
                      <div
                        style={{
                          color: row.color,
                          fontSize: 8,
                          letterSpacing: 2,
                          textTransform: "uppercase",
                        }}
                      >
                        {row.name}
                      </div>
                      <div
                        style={{
                          color: row.color,
                        }}
                      >
                        {row.value} AED
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null;
          }}
          wrapperStyle={{
            background: "rgba(255,255,255,0.9)",
            borderRadius: 4,
            padding: "5px 8px",
            fontWeight: 500,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        />
        <XAxis tickLine={false} dataKey="month" axisLine={false} />
        <Area
          dataKey={bottomChart}
          stackId="2"
          stroke={bottomChartColor}
          fillOpacity={0.5}
          strokeOpacity={0.3}
          fill={bottomChartColor}
        />
        <Area
          dataKey={topChart}
          stackId="1"
          stroke={topChartColor}
          fillOpacity={0.8}
          strokeOpacity={0.3}
          fill={topChartColor}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default EarningExpensesChart;
